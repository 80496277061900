import { Atom, Cmp, Ctrl } from ":mods";
import { _events_actions, _token_actions } from "../store";
import { getUserEvents } from "../apis";

// TODO: incase user is jumping between users in development server
export const checkUserEvents: Atom.Route.model.AGuardStep = {
  runOn: ["app_init", "first_load"],
  loaderMsg: "checking events...",
  async checker({ state, base, routeMatch, routeMatchBase }) {
    // console.log("checkUserEvents guard loaded :: with state :: ", state, " :: and base :: ", base, " :: ");
    const is_auth_route = routeMatchBase(Ctrl.Routes.CONST.BASE_ROUTES.auth);
    if (is_auth_route) {
      return undefined;
    }
    return getUserEvents()
      .then((events) => {
        // console.log("user events :: ", events);
        _events_actions.set(events?.events);
        return Promise.resolve(undefined);
      })
      .catch((e) => {
        // console.log("user events errored :: ", e);
        return Promise.resolve({
          error: "user events errored!",
          navigate: {
            // path: routeMatch(BASE_ROUTES.auth) ? undefined : Mod.Account.CONST.ROUTES.login,
            // base: BASE_ROUTES.auth,
          },
        });
      });
  },
};
